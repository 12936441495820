.main-menu {
  display: none;
}

.main-menu ul {
  display: inherit;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.main-menu li {
  display: inherit;
  width: 11.25em;
  padding: 0.25em 2em;
  font-size: 1.2em;
  background-image: linear-gradient(110deg, #c14949 50%, #c1494970 100%);
}

.main-menu a {
  text-decoration: none;
}

.dark-mode .main-menu a:hover,
.dark-mode span:hover {
  text-decoration: underline;
}

.dark-mode .main-menu a,
.dark-mode .main-menu a:visited,
.dark-mode .main-menu a:active,
.dark-mode span {
  color: #ffffff;
}

.light-mode .main-menu a:hover {
  text-decoration: underline;
}

.light-mode .main-menu a,
.light-mode .main-menu a:visited,
.light-mode .main-menu a:active {
  color: #ffffff;
}

.dark-mode .main-menu button {
  border: none;
  color: #000;
  background-color: #fff;
}

.light-mode .main-menu button {
  border: none;
  color: #fff;
  background-color: #000;
}

.main-menu .signout-btn {
  margin: 0;
  padding: 0;
  text-decoration: underline;
}

.dark-mode .main-menu .signout-btn {
  color: #fff;
  background-color: #000;
}

.light-mode .main-menu .signout-bbtn {
  color: #000;
  background-color: #fff;
}
