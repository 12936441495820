.sungka-board {
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(10, 1fr);
}

.counters,
.counters * {
  position: relative;
  display: inline;
  height: min-content;
}

.left-space {
  grid-row: 1 / span 10;
  grid-column: 1 / span 2;
}

.top-space {
  grid-row: 1 / span 2;
  grid-column: 3 / span 21;
}

.right-space {
  grid-row: 1 / span 10;
  grid-column: 23 / span 2;
}

.bottom-space {
  grid-row: 9 / span 2;
  grid-column: 3 / span 21;
}

.middle-space {
  grid-row: 5 / span 2;
  grid-column: 5 / span 16;
}

.ulo-p1-hole {
  grid-row: 5 / span 2;
  grid-column: 3 / span 2;
  display: grid;
  grid-template-areas: "ulo-p1-hole";
  place-content: center;
}

.ulo-p2-hole {
  grid-row: 5 / span 2;
  grid-column: 21 / span 2;
  display: grid;
  grid-template-areas: "ulo-p2-hole";
  place-content: center;
}

.bahay01 {
  grid-row: 7 / span 2;
  grid-column: 18 / span 2;
  display: grid;
  grid-template-areas: "bahay01";
  place-content: center;
}

.bahay02 {
  grid-row: 7 / span 2;
  grid-column: 16 / span 2;
  display: grid;
  grid-template-areas: "bahay02";
  place-content: center;
}

.bahay03 {
  grid-row: 7 / span 2;
  grid-column: 14 / span 2;
  display: grid;
  grid-template-areas: "bahay03";
  place-content: center;
}

.bahay04 {
  grid-row: 7 / span 2;
  grid-column: 12 / span 2;
  display: grid;
  grid-template-areas: "bahay04";
  place-content: center;
}

.bahay05 {
  grid-row: 7 / span 2;
  grid-column: 10 / span 2;
  display: grid;
  grid-template-areas: "bahay05";
  place-content: center;
}

.bahay06 {
  grid-row: 7 / span 2;
  grid-column: 8 / span 2;
  display: grid;
  grid-template-areas: "bahay06";
  place-content: center;
}

.bahay07 {
  grid-row: 7 / span 2;
  grid-column: 6 / span 2;
  display: grid;
  grid-template-areas: "bahay07";
  place-content: center;
}

.bahay08 {
  grid-row: 3 / span 2;
  grid-column: 6 / span 2;
  display: grid;
  grid-template-areas: "bahay08";
  place-content: center;
}

.bahay09 {
  grid-row: 3 / span 2;
  grid-column: 8 / span 2;
  display: grid;
  grid-template-areas: "bahay09";
  place-content: center;
}

.bahay10 {
  grid-row: 3 / span 2;
  grid-column: 10 / span 2;
  display: grid;
  grid-template-areas: "bahay10";
  place-content: center;
}

.bahay11 {
  grid-row: 3 / span 2;
  grid-column: 12 / span 2;
  display: grid;
  grid-template-areas: "bahay11";
  place-content: center;
}

.bahay12 {
  grid-row: 3 / span 2;
  grid-column: 14 / span 2;
  display: grid;
  grid-template-areas: "bahay12";
  place-content: center;
}

.bahay13 {
  grid-row: 3 / span 2;
  grid-column: 16 / span 2;
  display: grid;
  grid-template-areas: "bahay13";
  place-content: center;
}

.bahay14 {
  grid-row: 3 / span 2;
  grid-column: 18 / span 2;
  display: grid;
  grid-template-areas: "bahay14";
  place-content: center;
}
