.status-bar div .display-name {
  height: 100%;
  font-size: 1em;
  line-height: 62px;
  padding: 0 6px;
  text-decoration: none;
  font-family: "Bebas Neue", cursive;
  font-weight: 400;
  color: #fff;
  background-color: #0084ff;
}
