.btn.menu,
div .btn.signout,
div .btn.signin {
  height: 100%;
  font-size: 1em;
  line-height: 3em;
  text-decoration: none;
  font-weight: 400;
  line-height: 62px;
  padding: 0 6px;
}

.btn.menu {
  bottom: 0;
  right: 0;
  display: inline-block;
  width: 50px;
  height: 50px;
  padding: 5px;
  margin: 6px;
  border-radius: 100%;
  background-image: url("./menuButton.svg");
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-position: center;
  cursor: pointer;
  box-shadow: -1px -1px 0 #1591d8, 1px 1px 0 #c1494981;
  border: none;
}

.status-bar .btn.signin,
.status-bar div .btn.signout {
  color: hsl(240, 100%, 50%);
  background-color: hsl(120, 49%, 52%);
}

.status-bar .btn.signin:hover,
.status-bar div .btn.signout:hover {
  color: hsl(240, 100%, 30%);
  background-color: hsl(120, 69%, 72%);
  text-decoration: underline;
}

.status-bar .btn.signin {
  border: 00.125em solid hsl(120, 49%, 52%);
}
